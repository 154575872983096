import React from "react";
import { dbConst } from "./const";
import { GisLayerFeatures, OrganizationResponse, Rest } from "./rest";
import { CacheDb } from "./cache";

export type Database = {
    SetUid(uid: string): void
    Org(force: boolean): Promise<OrganizationResponse>
    GeoLayers(force: boolean): Promise<string[]>
    LayerGis(layer: string, force: boolean): Promise<GisLayerFeatures>
    Clear(): Promise<void>
    GetRequestedDrawPolygon(requestPoloygonKey: string): Promise<GeoJSON.Point[]>
}

export const DatabaseContext = React.createContext<Database | undefined>(undefined);


export const createDataBase = async (host: string, uid: string) => {
    return new Promise<Database>(async (resolve, reject) => {
        const rest = await new Rest(host, uid);

        await setupIndexDb()
            .then((db) => {
                const cache = new CacheDb(rest, db);
                resolve(cache);
            })
            .catch((err) => {
                console.log(`Error ${err}`);
                resolve(rest);
            })
    })
}

async function setupIndexDb(): Promise<IDBDatabase> {
    return new Promise(function (resolve, reject) {
        const request = indexedDB.open(dbConst.storageName, 1)
        request.onupgradeneeded = function (event: any) {
            const db = event.target?.result;
            db.createObjectStore(dbConst.gisStorage.geoStore);
            db.createObjectStore(dbConst.dataStorage.geoStore);
            db.createObjectStore(dbConst.versionStorage.versionStore);

        }
        request.onerror = function (event) {
            console.log(`DB Error ${event}`);
            return reject(event);
        }
        request.onsuccess = function (event: any) {
            const db = event.target.result;
            return resolve(db);
        }
    });
}