import React from "react";
import { GisProps } from "../components/map";
import { useSearchParams } from "react-router-dom";
import utf8 from "utf8";
import { Base64 } from "js-base64";

export interface flutter {
    uid: string;
    startingPoint: {lat: Coords, lng: Coords},
    polygonCoords: Coords[];
    requestedDrawPolygon: string;
}

export interface Coords {
    lat: string;
    lng: string;
}

export function useUrlMap() {
    const [searchParams] = useSearchParams();
    const mapdata = JSON.parse(utf8.decode(Base64.decode(decodeURIComponent(searchParams.get("mapdata") ?? "e30=" as string)))) as flutter;
    console.log(mapdata);

    const lat = (Object.hasOwn(mapdata, "startingPoint")) ? Number(mapdata.startingPoint.lat.lat) : 0;
    const lng = (Object.hasOwn(mapdata, "startingPoint")) ? Number(mapdata.startingPoint.lat.lng) : 0;

    const [urlRequestSettings,] = React.useState<GisProps>({
        uid: (import.meta.env.PROD) ? mapdata.uid : (import.meta.env.VITE_UID),
        map: (Object.hasOwn(mapdata, "startingPoint")) ? {
            lon: Math.min(lat, lng),
            lat: Math.max(lat, lng),
            zoom: 15,
        } : undefined,
        drawPolygon: (mapdata.polygonCoords) ? mapdata.polygonCoords.map<GeoJSON.Point>((coords) => {
            return {
                type: "Point",
                coordinates: [Number(coords.lat), Number(coords.lng)],
            };
        }) : [],
        requestedDrawPolygon: mapdata.requestedDrawPolygon
    });


    return urlRequestSettings;
}