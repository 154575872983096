export const dbConst = {
    storageName: "gis",
    gisStorage: {
        geoStore: "gis",
        keys: {
            layers: "layers",
            geoJson: "geoJson",
        }
    },
    dataStorage: {
        geoStore: "data",
        keys: {
            layers: 'layers',
        }
    },
    versionStorage: {
        versionStore: "version",
        keys: {
            layers: 'layers',
        }
    }
};
