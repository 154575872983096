import { Snackbar } from "@mui/material";
import { useAppSelector } from "../../state/store";

export function LoadingGis() {
    const loading = useAppSelector(state => state.geo.loading);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={loading.isLoading}
            message={`reason ${loading.reason} | gis loading ${loading.gisPercent.toFixed(0)}`}
        />
    )
}