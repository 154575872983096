import { dbConst } from "./const";
import { GisLayerFeatures, OrganizationResponse, Rest } from "./rest";

export class CacheDb {
    constructor(private rest: Rest, private db: IDBDatabase) { }

    SetUid(uid: string): void {
        this.rest.SetUid(uid);
    }

    async GeoLayers(force: boolean): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            const data = await this.getFromStore(dbConst.gisStorage.geoStore, dbConst.gisStorage.keys.layers);
            if (data === null || force === true) {
                const layers = await this.rest.GeoLayers();
                await this.storeToStore(dbConst.gisStorage.geoStore, dbConst.gisStorage.keys.layers, layers);
                resolve(layers);
            }
            console.log(`GeoLayers found in cache ${data}`);
            resolve(data);
        });
    }

    async LayerGis(layer: string, force: boolean): Promise<GisLayerFeatures> {
        return new Promise(async (resolve, reject) => {
            const key = dbConst.gisStorage.keys.layers + "-" + layer;
            const data = await this.getFromStore(dbConst.gisStorage.geoStore, key);
            if (data === null || force === true) {
                console.log("LayerGis not found in cache");
                const features = await this.rest.LayerGis(layer);
                await this.storeToStore(dbConst.gisStorage.geoStore, key, features);
                resolve(features);
            }
            resolve(data);
        });
    }

    getFromStore(storeName: string, key: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let tx = this.db.transaction(storeName, "readwrite");
            let store = tx.objectStore(storeName);
            const g = store.get(key);
            g.onsuccess = async (event: any) => {
                if (event.target.result) {
                    console.log("getFromStore found in cache");
                    resolve(event.target.result);
                }
                resolve(null);
            };
            g.onerror = (e) => {
                console.log("getFromStore get from cache failed", e);
                reject(e);
            };
        });
    }

    storeToStore(storeName: string, key: string, value: any): Promise<void> {
        return new Promise((resolve, reject) => {
            let tx = this.db.transaction(storeName, "readwrite");
            let store = tx.objectStore(storeName);
            const g = store.put(value, key);
            g.onsuccess = async (event: any) => {
                console.log("storeToStore success");
                resolve();
            };
            g.onerror = (e) => {
                console.log("storeToStore failed", e);
                reject(e);
            };
        });
    }

    Clear(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            let tx = this.db.transaction(dbConst.gisStorage.geoStore, "readwrite");
            let store = tx.objectStore(dbConst.gisStorage.geoStore);
            await store.clear();

            tx = this.db.transaction(dbConst.dataStorage.geoStore, "readwrite");
            store = tx.objectStore(dbConst.dataStorage.geoStore);
            await store.clear();

            tx = this.db.transaction(dbConst.versionStorage.versionStore, "readwrite");
            store = tx.objectStore(dbConst.versionStorage.versionStore);
            await store.clear();

            resolve();
        });
    }

    Org(force: boolean): Promise<OrganizationResponse> {
        return this.rest.Org(force);
    }

    async GetRequestedDrawPolygon(requestPoloygonKey: string): Promise<GeoJSON.Point[]> {
       return await this.rest.GetRequestedDrawPolygon(requestPoloygonKey);
    }
}