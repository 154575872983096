import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Database } from "../data";
import { postToFlutter } from "../flutter/flutter";

export type MapState = {
    position: {
        latitude?: number,
        longitude?: number,
        zoom?: number,
    }
    isLoaded: boolean,
    selectedPositions: MapPosition[],
    selected: MapPosition | undefined,
    hover: MapBoxSelectedLayer | undefined,
    selectedMapBoxLayer: MapBoxSelectedLayer | undefined,
}

export type MapPosition = {
    uid: string,
    layer: string,
    source: string,
    type: string,
}

export type MapBoxSelectedLayer = {
    layer: string,
    source: string,
    filter: any,
};

const initialState: MapState = {
    position: {
    },
    isLoaded: false,
    selectedPositions: [],
    selected: undefined,
    hover: undefined,
    selectedMapBoxLayer: undefined,
}

export const setSelected = createAsyncThunk(
    "map/setSelected",
    async (params: {
        positions: MapPosition[],
    }, thunkAPI) => {
        const selectedPositions = params.positions.filter((position: MapPosition) => position.uid !== undefined);
        if (selectedPositions.length === 1) {
            thunkAPI.dispatch(mapSlice.actions.setSelected({ selected: selectedPositions[0] }));
        }
        return {
            selectedPositions: selectedPositions,
            selected: selectedPositions.length === 1 ? selectedPositions[0] : undefined,
        };
    }
);

export const setInit = createAsyncThunk(
    "map/setInit",
    async (params: {
        deps: Database,
    }) => {
        const org = params.deps.Org(true)
        return (await org).org.map;
    });

export const mapSlice = createSlice({
    name: "map",
    initialState: initialState,
    reducers: {
        mapIsLoaded(state, action) {
            state.isLoaded = true;
        },
        setMapPosition(state, action) {
            state.position = action.payload.position;
        },
        setHover(state, action) {
            const hover = action.payload.hover;
            if (hover === undefined) {
                state.hover = undefined;
            } else {
                state.hover = {
                    'layer': hover.layer,
                    'source': hover.source,
                    'filter': ['==', 'uid', hover.uid]
                }
            }
        },
        setSelected(state, action) {
            const selected = action.payload.selected as MapPosition | undefined;
            state.hover = undefined;
            state.selected = selected;

            if (selected === undefined) {
                state.selectedMapBoxLayer = undefined;
            } else {
                state.selectedMapBoxLayer = {
                    'layer': selected.layer,
                    'source': selected.source,
                    'filter': ['==', 'uid', selected.uid]
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setSelected.fulfilled, (state, action) => {
            state.selectedPositions = action.payload.selectedPositions;
            if (action.payload.selected !== undefined) {
                state.selected = action.payload.selected;
            }
            if (state.selectedPositions.length === 1) {
                postToFlutter("setSelected", {
                    selected: action.payload.selected,
                });
            } else {
                postToFlutter("selectedPositions", {
                    selectedPositions: action.payload.selectedPositions,
                })
            }
        });
        builder.addCase(setInit.fulfilled, (state, action) => {
            state.position = {
                latitude: action.payload.lat_start_point,
                longitude: action.payload.long_start_point,
                zoom: 15,
            }
        });
    },
})

