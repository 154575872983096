import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Database } from "../data";
import { Organization, User } from "../data/rest";

export type GisLayerFeatures = {
    layerName: string,
    geoJson?: GeoJSON.FeatureCollection,
    style: any
}


export const initData = createAsyncThunk(
    'geo/init',
    async (params: {
        deps: Database,
        reason: string,
        requestedDrawPolygon: GeoJSON.Point[],
        requestDrawPolygonKey?: string,
        force: boolean
    }, thunkAPI) => {
        console.log(`intitData: ${!!params.requestDrawPolygonKey}`);
        const org = await params.deps.Org(true);

        let drawPolygon = params.requestedDrawPolygon;
        if (!!params.requestDrawPolygonKey) {
            console.log("getting requested draw polygon");
            drawPolygon = await params.deps.GetRequestedDrawPolygon(params.requestDrawPolygonKey);
            console.log(`drawPolygon: ${JSON.stringify(drawPolygon)}`);
        }else{
            drawPolygon =drawPolygon.map<GeoJSON.Point>((point) => {
                const lat = point.coordinates[1];
                const lng = point.coordinates[0];
                return {
                    type: "Point",
                    coordinates: [Math.min(lat, lng), Math.max(lat, lng)],
                }
            })
        }

        const layers = await params.deps.GeoLayers(params.force);
        let fetch: GisLayerFeatures[] = [];

        const setProgress = () => {
            thunkAPI.dispatch(geoSlice.actions.setLoading({
                reason: params.reason,
                isLoading: true,
                gisPercent: (fetch.length / layers.length) * 100,
            }));
        }

        for (const layer of layers) {
            fetch.push(await params.deps.LayerGis(layer, params.force));
            setProgress();
        }

        let gis = fetch.reduce((acc, cur) => {
            acc[cur.layerName] = cur;
            return acc;
        }, {} as { [layerName: string]: GisLayerFeatures });

        let ticketsLayer = gis["tickets-811"];
        if (ticketsLayer) {
            if (!ticketsLayer.style.layout) {
                ticketsLayer.style.layout = {};
            }
            ticketsLayer.style.layout.visibility = "none";
            gis["tickets-811"] = ticketsLayer;
        }


        gis["add-point"] = {
            layerName: "add-point",
            geoJson: {
                type: "FeatureCollection",
                features: [
                    {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [0, 0,]
                        },
                        "properties": {
                            "uid": "add-point",
                        }
                    },
                ],
            },
            style: {
                "id": "add-point",
                "paint": {
                    "circle-color": "green",
                    "circle-radius": 10,
                    "circle-stroke-color": "white",
                    "circle-stroke-width": 2
                },
                "layout": {
                    "visibility": "none",
                },
                "source": "add-point",
                "type": "circle"
            }
        };

        const points = drawPolygon.map((point) => [point.coordinates[0], point.coordinates[1]]);
        console.log(points);
        gis["requested-polygon"] = {
            layerName: "requested-polygon",
            geoJson: {
                type: "FeatureCollection",
                features: [
                    {
                        "type": "Feature",
                        "geometry": {
                            "type": "Polygon",
                            'coordinates': [
                                points,
                            ]
                        },
                        "properties": {
                            "uid": "requested-polygon",
                        }
                    },
                ],
            },
            style: {
                "id": "requested-polygon",
                'paint': {
                    'fill-color': '#ff0000', // blue color fill
                    'fill-opacity': .5,
                },
                "source": "requested-polygon",
                "type": "fill"
            },
        }

        return {
            layers: layers,
            gis: gis,
            outOfSync: false,
            org: org,
        }
    }
)

export const fetchLayers = createAsyncThunk(
    'geo/fetchLayers',
    async (params: {
        deps: Database,
    }, thunkAPI) => {
        return params.deps.GeoLayers(false);
    }
);

export const fetchLayerGis = createAsyncThunk(
    'geo/fetchLayerGis',
    async (params: {
        deps: Database,
        layer: string,
        force: boolean,
    }, thunkAPI) => {
        return params.deps.LayerGis(params.layer, params.force);
    }
);

export const clearLocalData = createAsyncThunk(
    'geo/clearLocalData',
    async (params: {
        deps: Database,
        shouldReload: boolean,
    }, thunkAPI) => {
        console.log("clearing local data");
        await params.deps.Clear();
        thunkAPI.dispatch(initData({
            deps: params.deps,
            reason: "clearLocalData",
            requestedDrawPolygon: [],
            requestDrawPolygonKey: undefined,   
            force: true,
        }));
    }
);

export type GeoState = {
    loading: {
        reason: string,
        isLoading: boolean,
        gisPercent: number,
    }
    layers: string[],
    gis: { [layerName: string]: GisLayerFeatures },
    org: Organization | undefined,
    user: User | undefined,
    outOfSync: boolean,
};

const initState: GeoState = {
    loading: {
        reason: "init",
        isLoading: true,
        gisPercent: 0,
    },
    layers: [],
    gis: {},
    org: undefined,
    user: undefined,
    outOfSync: false,
};

export const geoSlice = createSlice({
    name: "geo",
    initialState: initState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        updateAddPoint(state, action) {
            let p = state.gis["add-point"]
            p.geoJson = action.payload
            state.gis["add-point"] = p
        },
        setAddPointVisibility(state, action) {
            let p = state.gis["add-point"];
            p.style.layout.visibility = (action.payload) ? "visible" : "none";
            state.gis["add-point"] = p;
        },
        hideLayers(state, action) {
            let payload = action.payload.layers as string[];
            for (const layer of state.layers) {
                let p = state.gis[layer];
                if (!p.style.layout) {
                    p.style.layout = {};
                }
                p.style.layout.visibility = (payload.includes(layer)) ? "none" : "visible";
                state.gis[layer] = p;
            }
        },
        showOnlyUID(state, action) {
            let payload = action.payload.uids as string[];
            for (const layer in state.gis) {
                if (layer != "tickets-811") {
                    continue;
                }
                let newFeatures = [];
                for (const feature of state.gis[layer].geoJson?.features ?? []) {
                    if (!feature.properties) {
                        continue;
                    }
                    let j = JSON.parse(JSON.stringify(feature.properties));
                    if (!j["uid"]) {
                        continue;
                    }
                    let uid = j["uid"] ?? "";
                    if (payload.includes(uid)) {
                        newFeatures.push(feature);
                    }
                }
                state.gis[layer].geoJson!.features = newFeatures;
                let style = state.gis[layer].style;
                if (!style.layout) {
                    style.layout = {};
                }
                style.layout.visibility = "visible";
                state.gis[layer].style = style;
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(initData.fulfilled, (state, action) => {
            state.loading = {
                reason: "done",
                isLoading: false,
                gisPercent: 100,
            }
            state.layers = action.payload.layers;
            state.gis = action.payload.gis;
            state.outOfSync = action.payload.outOfSync;
            state.org = action.payload.org.org;
            state.user = action.payload.org.user;
        });
        builder.addCase(fetchLayers.fulfilled, (state, action) => {
            state.layers = action.payload;
        });
        builder.addCase(fetchLayerGis.fulfilled, (state, action) => {
            state.gis[action.payload.layerName] = action.payload;
        });
        builder.addCase(clearLocalData.fulfilled, (state, action) => {
            state.gis = {};
            state.layers = [];
        });
    },
});