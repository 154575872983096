export class Rest {
    constructor(private baseUrl: string, private uid: string) {
    }

    public SetUid(uid: string) {
        this.uid = uid;
    }

    private getHeader(): Headers {
        const headers = new Headers();
        headers.append("uid-token", this.uid);
        return headers;
    }

    // Calls teh /admin/geo-coords endpoint and gets the coords
    async GetRequestedDrawPolygon(requestPoloygonKey: string): Promise<GeoJSON.Point[]> {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: this.getHeader(),
        };
        const coords = await fetch(
            `${this.baseUrl}/admin/geo-coords?ticketKey=${requestPoloygonKey}`,
            requestOptions
        );

        const data = await coords.json() as {
            lng: string
            lat: string
        }[];

        return data.map((coord) => {
            const lat = Number(coord.lat);
            const lng = Number(coord.lng);
            return {
                type: "Point",
                coordinates: [Number(Math.min(lat,lng)), Number(Math.max(lat,lng))]
            }
        });
    }

    async GeoLayers(force = false): Promise<string[]> {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: this.getHeader(),
        };
        const resp = await fetch(
            `${this.baseUrl}/admin/geo-layers`,
            requestOptions
        );
        const data = await resp.json();
        return Promise.resolve(data as string[]);
    }

    async LayerGis(layer: string, force = false): Promise<GisLayerFeatures> {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: this.getHeader(),
        };

        const features = fetch(
            `${this.baseUrl}/admin/geo-features?` + new URLSearchParams({
                "layer": layer,
            }),
            requestOptions
        );

        const layerStyle = fetch(
            `${this.baseUrl}/admin/geo-layers-style?` + new URLSearchParams({
                "layer": layer,
            }),
            requestOptions
        );

        // Way is resp not is the Promise.all
        // Aslo should I put the coords in the Promise.all
        const apiResults = await Promise.all([features, layerStyle]);
        const geoJson = await apiResults[0].json();
        const geoStyle = await apiResults[1].json();

        const result = {
            layerName: layer,
            geoJson: geoJson.data,
            geoJsonVersion: geoJson.version ?? 0,
            style: geoStyle.data.style,
            styleVersion: geoStyle.version ?? 0,
        };

        console.log(result)

        return Promise.resolve(result);
    }

    Clear(): Promise<void> {
        return Promise.resolve();
    }

    DoesNeedToUpdate(): Promise<Update[]> {
        return new Promise(async (resolve, reject) => {
            const layers = await this.GeoLayers(true);
            layers.map((layer) => {
                return {
                    layer: layer,
                    gis: true,
                    style: true,
                    data: true,
                } as Update;
            });
        })
    }

    Org(force: boolean): Promise<OrganizationResponse> {
        return new Promise(async (resolve, reject) => {
            const requestOptions: RequestInit = {
                method: "GET",
                headers: this.getHeader(),
            };
            const request = await fetch(
                `${this.baseUrl}/admin/user`,
                requestOptions,
            );

            const data = await request.json();
            return resolve(data as OrganizationResponse);
        });
    }
}


export type GisLayerFeatures = {
    layerName: string,
    geoJson: GeoJSON.FeatureCollection,
    geoJsonVersion: number,
    style: mapboxgl.AnyLayer
    styleVersion: number,
}

export type Update = {
    layer: string,
    gis: boolean,
    style: boolean,
    data: boolean,
}

export type OrganizationResponse = {
    user: User,
    org: Organization
}

export type Organization = {
    id: number,
    map: OrganizationMap
    users: User[]
}

export type OrganizationMap = {
    lat_start_point: number;
    long_start_point: number;
}

export type User = {
    uid: string,
    first_name: string;
    last_name: string;
}