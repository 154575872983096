import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { DatabaseContext, createDataBase } from './data'
import './index.css'
import { store } from './state/store'
import { BrowserRouter } from 'react-router-dom'
import { Setup } from './components/setup'

(async function main() {
  const database = await createDataBase(import.meta.env.VITE_REST_URL, '');

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <BrowserRouter>
      <Provider store={store}>
        <DatabaseContext.Provider value={database}>
          <Setup />
        </DatabaseContext.Provider>
      </Provider>
    </BrowserRouter>
  )
})();

