import mapboxgl from "mapbox-gl";
import { useEffect, useRef } from "react";
import { MapPosition, mapSlice, setSelected } from "../state/map";
import { useAppDispatch, useAppSelector } from "../state/store";

mapboxgl.accessToken = import.meta.env.VITE_MAP_BOX_TOKEN;

export type GisProps = {
    uid: string,
    map: {
        lon: number,
        lat: number,
        zoom: number,
    } | undefined,
    drawPolygon: GeoJSON.Point[],
    requestedDrawPolygon: string,
}


export function GisMap(props: GisProps) {
    const dispatch = useAppDispatch();

    const mapContainer = useRef<HTMLDivElement | null>(null);
    const map = useRef<mapboxgl.Map | null>(null);
    const mapIsLoaded = useAppSelector(state => state.map.isLoaded);

    const gis = useAppSelector(state => state.geo.gis);
    const location = useAppSelector(state => state.map.position);
    const selectedLayer = useAppSelector(state => state.map.selectedMapBoxLayer);

    useEffect(() => {
        if (map.current && location.latitude && location.longitude) {
            map.current.flyTo({
                duration: 0,
                center: [location.longitude, location.latitude],
                zoom: location.zoom ?? 15,
                essential: true
            });
        }
    }, [location])

    useEffect(() => {
        if (map.current !== null) return;
        let center = {
            lat: props.map?.lat ?? 0,
            lng: props.map?.lon ?? 0,
            zoom: props.map?.zoom ?? 15,
        }
        if (props.drawPolygon.length > 1) {
            const lat = props.drawPolygon[0].coordinates[1];
            const lng = props.drawPolygon[0].coordinates[0];

            center = {
                lat: Math.max(lat, lng),
                lng:  Math.min(lat, lng),
                zoom: 15,
            }
        }

        console.log(`center -> ${JSON.stringify(center)}`)

        map.current = new mapboxgl.Map({
            container: mapContainer.current!,
            style: "mapbox://styles/mapbox/streets-v12",
            center: {
                lat: center.lat,
                lng: center.lng,
            },
            zoom: center.zoom,
            logoPosition: undefined,
        });

        map.current.on("load", () => {
            console.log("map loaded")
            dispatch(mapSlice.actions.mapIsLoaded({}));
        });

        map.current.on('mouseenter', 'clusters', () => {
            if (map.current) map.current.getCanvas().style.cursor = 'pointer';
        })

        map.current.on("click", (e) => {
            const features = map.current?.queryRenderedFeatures(e.point);
            let results: MapPosition[] = [];
            for (const feature of features ?? []) {
                if (feature && feature.properties && feature.layer.id !== "requested-polygon") {
                    results.push({
                        uid: feature.properties["uid"] as string,
                        layer: feature.layer.id,
                        source: feature.layer.source?.toString() ?? "",
                        type: feature.layer.type,
                    });
                }
            }
            console.log(`set selected -> ${JSON.stringify(results)}`)
            dispatch(setSelected({ positions: results }));
        });
    }, [map])

    useEffect(() => {
        if (mapIsLoaded) {
            const mp = map.current;
            const currentLayers = Object.keys(mp?.getStyle().sources ?? {});
            const remove = currentLayers.filter(key => !Object.keys(gis).includes(key));
            for (const key of remove) {
                if (key === "composite") continue;
                console.log("remove", key)
                mp?.removeLayer(key);
                mp?.removeSource(key);
            }
            console.log(Object.keys(gis));
            let keys = Object.keys(gis).filter(key => key != "requested-polygon");
            if (gis["requested-polygon"]) {
                keys = ["requested-polygon", ...keys];
            }
            console.log("keys", keys);


            for (const key of keys) {
                const layer = gis[key];
                console.log("layer", key, layer);
                const source = mp?.getSource(key);

                if (source) {
                    if (layer.geoJson) {
                        if (key === "add-point") {
                            mp?.removeLayer("add-point");
                            mp?.addLayer(layer.style);
                        }
                        (mp?.getSource(key) as mapboxgl.GeoJSONSource).setData(layer.geoJson);
                        mp?.removeLayer(key);
                        mp?.addLayer(layer.style);
                    }
                } else {
                    console.log("added source", key, layer.geoJson);
                    mp?.addSource(key, {
                        type: "geojson",
                        data: layer.geoJson,
                    });

                    let style = layer.style
                    if (style === undefined) {
                        console.error("style is undefined", layer)
                        continue;
                    }
                    console.log(`style -> ${JSON.stringify(style)}  `)
                    mp?.addLayer(style);
                }
            }
        }

    }, [gis, mapIsLoaded])

    useEffect(() => {
        if (mapIsLoaded) {
            const mp = map.current;
            const layer = mp?.getLayer("selected-feature");
            if (layer) {
                mp?.removeLayer("selected-feature");
            }
            if (selectedLayer) {
                console.log("selected layer", selectedLayer);
                mp?.addLayer(
                    {
                        "id": "selected-feature",
                        "paint": {
                            "circle-color": "green",
                            "circle-radius": 10,
                            "circle-stroke-color": "white",
                            "circle-stroke-width": 2
                        },
                        "source": selectedLayer.source,
                        "type": "circle",
                        "filter": selectedLayer.filter,
                    }
                );
            }
        }
    },
        [selectedLayer, mapIsLoaded]
    )

    return (
        <>
            <div ref={mapContainer} className="map-container" style={{ height: "100%", width: "100%" }} />
        </>
    )

}