import { DatabaseContext } from "../data";
import React, { useEffect } from "react";
import { useUrlMap } from "../hooks/urlMap";
import { useAppDispatch } from "../state/store";
import { clearLocalData, geoSlice, initData } from "../state/geo";
import { MapPosition, mapSlice, setInit, setSelected } from "../state/map";
import { GisMap } from "./map";
import { LoadingGis } from "./alerts/loading";

export function Setup() {
    const deps = React.useContext(DatabaseContext)!;
    const urlMap = useUrlMap();
    console.log(`urlMap: ${JSON.stringify(urlMap)}`);
    const dispatch = useAppDispatch();

    deps.SetUid(urlMap.uid);
    useEffect(() => {
        const setup = () => {
            dispatch(initData({
                deps: deps!,
                force: false,
                requestedDrawPolygon: urlMap.drawPolygon,
                requestDrawPolygonKey: urlMap.requestedDrawPolygon,
                reason: "init",
            }));
            if (urlMap.map === undefined) {
                dispatch(setInit({
                    deps: deps!,
                }));
            }
        }
        setup();
    }, [])

    useEffect(() => {
        function handleFlutterEvent(event: any) {
            try {
                const j = JSON.parse(event.data);
                console.log(`flutter event: ${event.data}`)
                switch (j["command"]) {
                    case "reload":
                        dispatch(initData({
                            deps: deps!,
                            force: true,
                            requestedDrawPolygon: urlMap.drawPolygon,
                            requestDrawPolygonKey: urlMap.requestedDrawPolygon,
                            reason: "reload",
                        }));
                        break;
                    case "goto":
                        const lat = Number(j["data"]["lat"]);
                        const lng = Number(j["data"]["lng"]);
                        const zoom = Number(j["data"]["zoom"]);
                        dispatch(mapSlice.actions.setMapPosition(
                            {
                                position: { latitude: lat, longitude: lng, zoom: zoom },
                            }));
                        break;
                    case "setPoint":
                        const point = j["data"];
                        var results: MapPosition[] = [];
                        results.push({
                            uid: point["uid"],
                            layer: point["layer"],
                            source: point["source"],
                            type: point["type"],
                        });
                        console.log(`selectPoint: ${JSON.stringify(results)}`);
                        dispatch(setSelected({ positions: results }));
                        break;
                    case "hiddenLayers":
                        const hiddenLayers = j["data"]["hiddenLayers"];
                        dispatch(geoSlice.actions.hideLayers({
                            layers: hiddenLayers,
                        }));
                        break;
                    case "showOnlyUid":
                        const showOnlyUid = j["data"]["uids"];
                        if (!showOnlyUid) return;
                        dispatch(geoSlice.actions.showOnlyUID({
                            uids: showOnlyUid,
                        }));
                        break;
                    case "clear":
                        dispatch(clearLocalData({
                            deps: deps,
                            shouldReload: true,
                        }));
                        break;
                }
            } catch (e) {
                console.log(`Command Error -> ${e}: ${event.data}`)
                return;
            }
        }
        window.addEventListener("message", handleFlutterEvent);
        return () => window.removeEventListener("flutter", handleFlutterEvent);
    })

    if (!urlMap.uid) return <p>not auth</p>

    return <>
        <LoadingGis />
        <GisMap {...urlMap} />;
    </>

}